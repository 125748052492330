<template>
  <div class="page">
    <div class="content">
      <div class="articles">
        <div class="li">
          <div class="title">{{info.title}}</div>
          <div class="time">{{info.mtime}}</div>
          <router-link class="author" tag="div" to="/lifes">
            <div class="tx"></div>
            <div class="text">
              <div class="who">Web前端前沿</div>
              <div class="whojs">致力于有趣的前端效果实现</div>
            </div>
            <div class="arrow"></div>
          </router-link>
          <div class="kong"></div>
          <div class="ms" v-html="info.nr" @click="opimg"></div>
          <div class="kong"></div>
          <div class="footerline">- EOF -</div>
          <div class="kong"></div>
          <router-link class="author" tag="div" to="/lifes">
            <div class="tx"></div>
            <div class="text">
              <div class="who">Web前端前沿</div>
              <div class="whojs">致力于有趣的前端效果实现</div>
            </div>
            <div class="arrow"></div>
          </router-link>
          <div class="kong"></div>
          <div class="kong"></div>
          <div class="hot">
            <div class="bt">推荐阅读</div>
            <div class="tjlist">
              <ul>
                <router-link tag="li" :to="'/lifes/'+item.id" v-for="(item,xh) in tjlist" :key="xh">{{item.title}}
                </router-link>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <transition name="mys">
        <div class="mark" v-if="expup" @click="gb"></div>
      </transition>
      <ul class="listall" :class="{expleft:expleft&&ispc,expup:expup&&!ispc}">
        <h2>文章列表<i class="iconfont icon-close" @click="gb"></i></h2>
        <router-link tag="li" :to="'/lifes/'+item.id" v-for="(item,xh) in dataList" :key="xh">{{item.title}}
        </router-link>
      </ul>
    </div>
  </div>
</template>
<script>
import {detail} from '@/api/api'

export default {
    name: 'articles',
    data() {
        return {
            info: {},
            articles: [],
            tjlist: [],
            ispc: true
        }
    },
    props: {
        expleft: {
            type: Boolean,
            default: true //默认default
        },
        expup: {
            type: Boolean,
            default: false //默认default
        },
        dataList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created() {
        if (this.$store.state.articles.hotLifes.length > 0) {
            this.tjlist = this.$store.state.articles.hotLifes
        } else {
            let data = {
                app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
                model_name: 'life',
                where: ["tj=1"]
            }
            this.$store.dispatch('articles/getArticles', data).then((rs) => {
                this.tjlist = rs
            })

        }

    },
    mounted() {
        let sw = window.innerWidth
        let _this = this
        cl(sw)
        window.addEventListener('resize', () => {
            sw = window.innerWidth
            cl(sw)
        })

        function cl(sw) {
            if (sw > 768) {
                _this.ispc = true
            } else {
                _this.ispc = false
            }
        }

        setTimeout(() => {
            let lh = document.querySelector('.listall').offsetHeight - 55
            let ah = this.dataList.length * 24
            if (ah > lh) {
                document.querySelector('.listall').style.cssText = '--gd:-15'
            } else {
                document.querySelector('.listall').style.cssText = '--gd:0'
            }
        }, 1400)

    },
    watch: {
        $route: {
            handler(route) {
                //this.$emit('update:expup', false)
                let id = Number(route.params.id)
                let data = {
                    app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
                    model_name: 'life',
                    id: id
                }
                detail(data).then((rs) => {
                    this.info = rs.data.data
                })

            },
            immediate: true
        },
        expup: {
            handler(value) {
                if (value) {
                    document.body.classList.add('dj')
                } else {
                    setTimeout(() => {
                        document.body.classList.remove('dj')
                    }, 400)
                }

            },
            immediate: true
        }
    },
    methods: {
        gb() {
            this.$emit('update:expup', false)
        },
        opimg() {
            let target = event.target
            if (target.tagName.toLowerCase().indexOf('img') > -1) {
                window.open(target.src)
            }
        }
    },
    computed: {}
}
</script>

